<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full relative">
            <div class="flex items-center justify-center h-screen">
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'App',
    data() {
        return {
        }
    },
    components: {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    },
    watch: {
    }
})
</script>

<style lang="scss">
</style>
